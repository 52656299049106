@import '../fonts.css';

/* Full-page container */
.reset-password-page {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "TAN-PEARL", sans-serif;
    overflow: hidden;
}

/* Main content */
.main-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* Rocket styling */
/* .rocket-container {
    margin-right: 3rem;
    margin-top: -4rem;
} */

.rocket {
    width: 613px; /* Figma specs */
    height: auto;
}

.form-title {
    font-size: 4rem; 
    font-weight: 400; 
    font-family: "TAN-PEARL", serif; 
    color: white; 
    text-align: center; 

    letter-spacing: 0.1rem;

    text-shadow: 
        0 0 10px rgba(255, 255, 255, 0.8),
        0 0 20px rgba(173, 216, 230, 0.4);
}

/* Reset Password-specific form styling */
.reset-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.reset-password-form .submit-button {
    align-self: center; 
    font-size: 1.5rem; 
    width: 100%;
}
.reset-password-form .input-group {
    width: 100%;
    font-size: 1.5rem;
}

/* Message Styling */
.message {
    margin-top: 2rem;
    font-size: 1.2rem;
    font-weight: 700; 
    color: rgb(255, 255, 255);
    font-family: "Anta", sans-serif;
    text-align: center;

    text-shadow: 
        0 0 10px rgba(255, 255, 255, 0.8), /* White inner glow */
        0 0 20px rgba(255, 255, 255, 0.6), /* Medium white glow */
        0 0 30px rgba(255, 255, 255, 0.4); /* Fainter white outer glow */
}

/* Responsiveness */
@media (max-width: 1400px) {
    .rocket {
        width: 400px;
    }
    .form-title {
        font-size: 3.5rem;
    }
}

@media (max-width: 1024px) {
    .rocket {
        width: 400px;
    }
    .form-title {
        font-size: 3rem;
    }
}

@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
        align-items: center;
    }
    .rocket-container {
        margin-bottom: 0rem;
        margin-top: 0rem;
    }
    .rocket {
        width: 300px;
    }
    .form-title {
        font-size: 2.5rem;
    }
}

@media (max-width: 480px) {
    .rocket {
        width: 250px;
    }
    .form-title {
        font-size: 2rem;
    }
    .reset-password-form {
        gap: 1rem;
    }
    .reset-password-form .submit-button {
        height: 80px;
    }
}