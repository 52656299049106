@import '../fonts.css';

.bus-info-title {
    font-size: 3rem;
}

.bus-info-text,
.bus-info-list {
    font-size: 2rem;
}

/* Media Queries */
@media (max-width: 1024px) {
    .bus-info-title {
        font-size: 2.5rem;
    }
    .bus-info-text,
    .bus-info-list {
        font-size: 1.8rem;
    }
}

@media (max-width: 768px) {
    .bus-info-title {
        font-size: 2.2rem;
    }
    .bus-info-text,
    .bus-info-list {
        font-size: 1.6rem;
    }
}

@media (max-width: 480px) {
    .bus-info-title {
        font-size: 2rem;
    }
    .bus-info-text,
    .bus-info-list {
        font-size: 1.4rem;
    }
}

@media (max-width: 400px) {
    .bus-info-title {
        font-size: 1.8rem;
    }
    .bus-info-text,
    .bus-info-list {
        font-size: 1.2rem;
    }
}

.custom-link {
    color: #92CEFF;
    text-decoration: none;
}

.custom-link:hover {
    text-decoration: underline;
}
