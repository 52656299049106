@import '../fonts.css';

/* Home Page */

.homepage {
  height: 100%;
}

.container {
  width: 100%; 
  display: flex;
  align-items: center; 
  min-height: 100vh; 
  margin-top: 0px;
  flex-direction: column;
  justify-content: center; 
}

/* Title */

.title {
  display: relative;
  margin-top: -45rem; 
}

.corner-image {
  position: absolute;
  top: 50px; /* Adjust to place the image where needed */
  left: 50px; /* Adjust to place the image where needed */
  width: 220px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  z-index: -1; /* Ensure it is layered correctly */
}


.title-image {
  display: block; 
  margin-right: auto; 
  margin-left: auto; 
  width: 70%; 
  height: auto; 
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .title-image {
    width: calc(100% - 20vw); 
  }
  .corner-image {
    width: 200px;
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
  .title-image {
    width: calc(100% - 10vw); 
  }
  .corner-image {
    width: 170px;
  }
}
@media (max-width: 768px) and (max-height: 480px) {
  .corner-image {
    width: 150px;
  }
}
@media (max-width: 768px) {
  .title-image {
    width: 100%; 
  }
  .corner-image {
    width: 150px;
  }
}



/* Date, Countdown, Clouds */

.countdown-container {
  display: flex;
  align-items: center;
  position: absolute;
  gap: 10px;
  opacity: 1;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, 370%);
  width: auto;
  white-space: nowrap; 
}

.hourglass {
  width: 36px;
  height: 36px;
  padding: 3px 7.5px;
  opacity: 1;
}

.home-clouds {
  position: absolute;
  top: 150px;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translateX(-50%);
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

/* COUNTDOWN */
@media (max-width: 1600px) {
  .hourglass {
    width: 28px;
    height: 28px;
    padding: 2px 6px;
  }
  
  .days-left.MuiTypography-root {
    font-size: 30px;
    line-height: 28px;
  }
}
@media (max-width: 1200px) {
  .hourglass {
    width: 22px;
    height: 22px;
    padding: 1.5px 4px;
  }
  
  .days-left.MuiTypography-root {
    font-size: 25px;
    line-height: 25px;
  }
}
@media (max-width: 768px) {
  .hourglass {
    width: 20px;
    height: 20px;
    padding: 1.5px 4px;
  }
  
  .days-left.MuiTypography-root {
    font-size: 23px;
    line-height: 23px;
  }
}
@media (max-width: 480px) {
  .hourglass {
    width: 15px;
    height: 15px;
    padding: 1px 3px;
  }
  
  .days-left.MuiTypography-root {
    font-size: 18px;
    line-height: 18px;
  }
}
@media (max-width: 350px) {
  .hourglass {
    width: 9px;
    height: 9px;
    padding: 1px 0px;
  }
  
  .days-left.MuiTypography-root {
    font-size: 12px;
    line-height: 12px;
  }
}


/* @media (max-width: 3000px) and (max-height: 600px) {
  .home-clouds {
    height: 20%;
    top: 0px;
  }
  .container {
    margin-bottom: 500px;
  }
}


@media (max-width: 900px) {
  .countdown-container {
    top: 60vh;
    transform: translate(-50%, 0%);
  }
}
@media (max-width: 768px) {
  

  .countdown-container {
    top: 55vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    top: 0px;
  }
  .container {
    margin-bottom: -100px;
  }
}
@media (max-width: 600px) {
  .countdown-container {
    top: 50vh;
    transform: translate(-50%, 0%);
  }
  .container {
    margin-bottom: -100px;
  }
}
@media (max-width: 480px) {

  .home-clouds {
    top: 0px;
  }
  .container {
    margin-bottom: -200px;
  }
}

@media (max-width: 250px) {
  .home-clouds {
    top: -50px;
  }
} */

@media (max-height: 1200px) and (min-width: 1200px) {
  .countdown-container {
    top: 70vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 7rem;
  }
  .container {
    margin-bottom: 500px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1200px) and (max-width: 1200px) {
  .countdown-container {
    top: 65vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 7rem;
  }
  .container {
    margin-bottom: 400px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1200px) and (max-width: 1024px) {
  .countdown-container {
    top: 65vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 7rem;
  }
  .container {
    margin-bottom: 300px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1200px) and (max-width: 900px) {
  .countdown-container {
    top: 60vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 7rem;
  }
  .container {
    margin-bottom: 300px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1200px) and (max-width: 768px) {
  .countdown-container {
    top: 60vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 7rem;
  }
  .container {
    margin-bottom: 200px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1200px) and (max-width: 480px) {
  .countdown-container {
    top: 45vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 2rem;
  }
  .container {
    margin-bottom: -20rem;
  }
  .title {
    margin-top: -50vh;
  }
}



@media (max-height: 1024px) and (min-width: 1200px) {
  .countdown-container {
    top: 70vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 500px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1024px) and (max-width: 1200px) {
  .countdown-container {
    top: 65vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 400px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1024px) and (max-width: 1024px) {
  .countdown-container {
    top: 65vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 300px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1024px) and (max-width: 900px) {
  .countdown-container {
    top: 60vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 300px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1024px) and (max-width: 768px) {
  .countdown-container {
    top: 60vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 0px;
  }
  .container {
    margin-bottom: 200px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 1024px) and (max-width: 480px) {
  .countdown-container {
    top: 60vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 0px;
  }
  .container {
    margin-bottom: 0px;
  }
  .title {
    margin-top: -30vh;
  }
}



@media (max-height: 768px) and (min-width: 1200px) {
  .countdown-container {
    top: 85vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 500px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 768px) and (max-width: 1200px) {
  .countdown-container {
    top: 80vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 400px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 768px) and (max-width: 1024px) {
  .countdown-container {
    top: 75vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 300px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 768px) and (max-width: 900px) {
  .countdown-container {
    top: 75vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 300px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 768px) and (max-width: 768px) {
  .countdown-container {
    top: 70vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 0px;
  }
  .container {
    margin-bottom: 200px;
  }
  .title {
    margin-top: -30vh;
  }
}
@media (max-height: 768px) and (max-width: 480px) {
  .countdown-container {
    top: 60vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -10rem;
  }
  .container {
    margin-bottom: 0px;
  }
  .title {
    margin-top: -30vh;
  }
}



@media (max-height: 480px) and (min-width: 1200px) {
  .countdown-container {
    top: 120vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 650px;
  }
  .title {
    margin-top: -20vh;
  }
}
@media (max-height: 480px) and (max-width: 1200px) {
  .countdown-container {
    top: 110vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 650px;
  }
  .title {
    margin-top: -20vh;
  }
}
@media (max-height: 480px) and (max-width: 1024px) {
  .countdown-container {
    top: 110vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 500px;
  }
  .title {
    margin-top: -20vh;
  }
}
@media (max-height: 480px) and (max-width: 900px) {
  .countdown-container {
    top: 110vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: -8rem;
  }
  .container {
    margin-bottom: 500px;
  }
  .title {
    margin-top: -20vh;
  }
}
@media (max-height: 480px) and (max-width: 768px) {
  .countdown-container {
    top: 100vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 0px;
  }
  .container {
    margin-bottom: 500px;
  }
  .title {
    margin-top: -20vh;
  }
}
@media (max-height: 480px) and (max-width: 480px) {
  .countdown-container {
    top: 90vh;
    transform: translate(-50%, 0%);
  }
  .home-clouds {
    height: 20%;
    top: 0px;
  }
  .container {
    margin-bottom: 500px;
  }
  .title {
    margin-top: -20vh;
  }
}

/* What is Sail, Schedule */

.about-sail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  margin-top: 20rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.schedule {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-top: 110px;
}

@keyframes starwidehome-animation {
  0% {
    transform: translateY(19px) scale(1); 
  }
  50% {
    transform: translateY(20px) scale(1.5); 
  }
  100% {
    transform: translateY(19px) scale(1); 
  }
}

@keyframes starwidehomenarrow-animation {
  0% {
    transform: translateY(19px) scale(1); 
  }
  50% {
    transform: translateY(20px) scale(1.5); 
  }
  100% {
    transform: translateY(19px) scale(1); 
  }
}


@keyframes starsmallhome-animation {
  0% {
    transform: translateY(-9px) scale(1); 
  }
  50% {
    transform: translateY(-10px) scale(1.5); 
  }
  100% {
    transform: translateY(-9px) scale(1); 
  }
}


.starwide-title {
  width: 55px;
  height: 55px;
  animation: starwidehome-animation 3s infinite ease-in-out;
}

.starsmall-title {
  width: 50px;
  height: 50px;
  animation: starsmallhome-animation 3s infinite ease-in-out;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.home-title {
  width: 90%;
  max-width: 1084px;
  height: 29px;
  font-family: 'Anta', sans-serif;
  font-size: 42px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.68px;
  text-align: center;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);
  opacity: 1;
  color: white;
}

@media (max-width: 1024px) {
  .about-desc.MuiTypography-root {
    font-size: 24px;
  }
  .home-title.MuiTypography-root {
    font-size: 48px;
  }
  .starwide-title {
    width: 40px;
    height: 40px;
  }
  .starsmall-title {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 768px) {
  .about-desc.MuiTypography-root {
    font-size: 20px;
  }
  .home-title.MuiTypography-root {
    font-size: 44px;
  }
  .starwide-title {
    width: 35px;
    height: 35px;
  }
  .starsmall-title {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 400px) {
  .about-desc.MuiTypography-root {
    font-size: 16px;
  }
  .home-title.MuiTypography-root {
    font-size: 40px;
  }
  .starwide-title {
    width: 30px;
    height: 30px;
  }
  .starsmall-title {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 350px) {
  .about-desc.MuiTypography-root {
    font-size: 12px;
  }
  .home-title.MuiTypography-root {
    font-size: 30px;
  }
  .starwide-title {
    width: 20px;
    height: 20px;
    animation: starwidehomenarrow-animation 3s infinite;
  }
  .starsmall-title {
    width: 15px;
    height: 15px;
    transform: translateY(-10px);
  }
}
@media (max-width: 250px) {
  .home-title.MuiTypography-root {
    font-size: 25px;
  }
}

/* Schedule Table */

.schedule-day {
  max-width: none;
  width: 100%;
  height: 29px;
  font-family: 'Anta';
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.68px;
  text-align: center;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);
  opacity: 1;
  color: white;
}

.schedule-day.MuiTypography-root {
  margin-bottom: 40px;
  margin-top: 40px;
  line-height: 1.2;
}

.schedule-table {
  margin-top: 30px;
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
  border-collapse: collapse;
  text-align: left; 
  table-layout: fixed; /* Ensures fixed column widths */
}

.schedule-table th, .schedule-table td {
  padding: 15px;
  font-family: "DM-Sans", sans-serif;
  font-size: 2.3em;
  color: white;
  text-align: left;
  vertical-align: top; /* Ensures multi-line text aligns correctly */
}

.schedule-table th:first-child,
.schedule-table td:first-child {
  width: 25%; /* 25% width for time column */
  min-width: 140px; /* Prevents shrinking too much */
  text-align: left;
  white-space: nowrap;
}

.schedule-table th:last-child,
.schedule-table td:last-child {
  width: 75%; /* 75% width for event column */
  text-align: left;
}

.schedule-table th {
  font-family: "Anta", sans-serif;
  font-size: 2.5em;
  color: white;
  padding: 8px 0;
  border-bottom: 2px solid white; 
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);
}

.schedule-table td {
  font-family: "DM-Sans", sans-serif;
  padding: 20px 0px 20px 0px; 
  font-size: 2.3em;
  color: white;
  text-align: left;
}

.schedule-table tr:not(:last-child) td {
  border-bottom: 1px solid white; 
}

.schedule-table tr:first-child th {
  border-top: none; 
}

.schedule-table tr:last-child td {
  border-bottom: none;
}

.note {
  margin-top: 2px;
  font-size: 0.6em;
  color: white; 
  font-family: "DM-Sans", sans-serif;
  margin-top: 4px; 
  display: block;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .schedule-day.MuiTypography-root {
    font-size: 35px;
    margin-bottom: 60px;
  }
  .schedule-table th {
    font-size: 2em;
  }
  .schedule-table td {
    font-size: 1.8em;
  }
}
@media (max-width: 768px) {
  .schedule-day.MuiTypography-root {
    font-size: 35px;
    margin-bottom: 60px;
  }
  .schedule-table th {
    font-size: 1.5em;
  }
  .schedule-table td {
    font-size: 1.3em;
  }
}
@media (max-width: 480px) {
  .schedule-day.MuiTypography-root {
    font-size: 30px;
    margin-bottom: 60px;
  }
  .schedule-table th {
    font-size: 1em;
  }
  .schedule-table td {
    font-size: 0.8em;
  }
}
@media (max-width: 300px) {
  .schedule-day.MuiTypography-root {
    font-size: 20px;
    margin-bottom: 80px;
  }
  .schedule-table th {
    font-size: 0.8em;
  }
}
@media (max-width: 180px) {
  .schedule-day.MuiTypography-root {
    font-size: 20px;
    margin-bottom: 100px;
  }
  .schedule-table th {
    font-size: 0.8em;
  }
}

/* Gallery Section */

.carousel_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20%;
}

.carousel_container img {
  border: 2px solid #ffffff; /* Adjust the color and size of the border as needed */
}