@import '../fonts.css';

/* Login Page */

.starwide-left, .starsmall-left {
    position: absolute;
    top: 25%;
    z-index: -1; 
    left: 5%;
    width: 40px; 
}
.starsmall-left {
    transform: translateY(70%) translateX(70%);
}
.nebula-right {
    position: absolute;
    opacity: 0.7;
    top: 30%;
    z-index: -1; 
    right: 15%;
    width: 350px;
}
@media (max-width: 1400px) {
    .nebula-right {  right: 10%; width: 320px; }
}
@media (max-width: 1200px) {
    .nebula-right {  width: 300px; }
}
@media (max-width: 1024px) {
    .nebula-right {  right: 5%; width: 300px; }
}
@media (max-width: 900px) {
    .nebula-right { right: 5%; width: 200px; }
  }
  @media (max-width: 768px) {
    .nebula-right { right: 0%; width: 200px; }
  }
@media (max-width: 600px) {
    .starwide-left, .starsmall-left, .nebula-right {  display: none; }
}

.loginpage {
    height: 100%;
    width: 100%;
    justify-content: center; 
    align-items: center; 
    min-height: 10vh; 
}

.authpage .auth-title.MuiTypography-root {
    line-height: 80px;
}

.subtitle.MuiTypography-root {
    margin-left: auto;
    margin-right: auto;
    font-size: 34px;
}

@media (max-width: 480px) {
    .subtitle.MuiTypography-root, .link.register {  font-size: 20px; }

}

.auth-title.MuiTypography-root {
    font-family: 'TAN-PEARL';
    font-size: 64px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0.68px;
    opacity: 1;
    text-align: center;
    color: white;
    text-shadow: 
        0 0 7px rgba(255, 255, 255, 0.7),
        0 0 10px rgba(255, 255, 255, 0.7),
        0 0 21px rgba(255, 255, 255, 0.7);
    margin-bottom: 40px; 
    margin-top: 20vh; 
}

@media (max-height: 768px) {
    .auth-title.MuiTypography-root {
        margin-top: 15vh;
        
    }
  }
  @media (max-height: 480px) {
    .auth-title.MuiTypography-root {
        margin-top: 25vh;
    }
  }

  @media (max-width: 480px) {
    .auth-title.MuiTypography-root {
        font-size: 48px;
    }
  }
  @media (max-width: 340px) {
    .auth-title.MuiTypography-root {
        font-size: 32px;
    }
  }


.subtitle.MuiTypography-root {
    font-family: 'Anta';
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
    color: white;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 500px) {
    .subtitle.MuiTypography-root { 
        max-width: calc(100% - 30px); 
        font-size: 16px;
    }
}

/* Form */
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%; 
    max-width: 500px; 
    margin: 0 auto; 
    padding: 0;
}

.input-group {
    font-family: 'Anta';
    font-size: 1.25rem;
    position: relative;
    --primary: #D9D9D966;
    width: 80%;
    display: flex;
    margin-bottom: 0.3rem; 
}
@media (max-width: 600px) {
    .input-group {
        width: 90%; /* Increase the width on mobile */
    }
    .input {
        width: 100%; /* Make the input field take the full width of the parent */
    }
}


.input {
    all: unset;
    width: 90%;
    color: #fefefe;
    padding: 1.5rem;
    padding-top: 2rem;
    border-radius: 20px;
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: var(--primary); 


    /* ensures styling consistent with autofill */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-text-fill-color: #fefefe !important;
        transition: background-color 5000s ease-in-out 0s;
        font-family: 'Anta';
    }
}

.label {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    color: #d4d4d4;
    pointer-events: none;
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: left top;
}

.input:focus {
    background-color: var(--primary);
}

.input:is(:focus, :valid) ~ .label {
    transform: translateY(-100%) translateX(0.5em) scale(0.7);
    padding-inline: 0em;
}

.submit-button {
    width: 70%;
    max-width: 400px; 
    height: 60px; 
    margin-top: 1.5rem;
    padding: 6px 24px;
    border: 3px solid #95C5FF;
    border-radius: 20px;
    background-color: #95C5FF80;
    
    /* Text styling */
    font-family: 'Anta'; 
    font-size: 1.5rem;
    color: white;
    text-align: center;
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);

    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-button:hover {
    background-color: rgba(149, 197, 255, 0.6);
    transform: translateY(-2px);
}

.submit-button:active {
    transform: translateY(1px);
}


/* Links */

.link {
    font-family: 'Anta'; 
    color: #92CEFF;
    text-decoration: underline;
    font-size: 20px;
}

.link:hover {
    text-decoration: underline;
    color: #92CEFF;
}

.forgot-password {
    margin-top: 15px;
    font-size: 20px;
    display: block;
    text-align: right;
    width: 80%;
    font-family: 'Anta';
}

.no-account.MuiTypography-root {
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
    font-family: 'Anta'; 
    color: white;
}


.register-link {
    display: inline-block; 
    margin-top: 20px; 
}


@media (max-width: 480px) {
    .link, .forgot-password, .no-account.MuiTypography-root {
        font-size: 16px;
    }
}
@media (max-width: 400px) {
    .submit-button {
    font-size: 20px;
  }
}
