.carousel {
    display: flex;
    flex-direction: column;
}

.carousel-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}


.button-63 {
  align-items: center;
  vertical-align: middle;
  background-color: #95C5FF80;
  border: 3px solid #95C5FF;
  border-radius: 50%;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 10px;
  justify-content: center;
  line-height: 1em;
  width: 70px;
  height: 70px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-63:active,
.button-63:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-63 {
    font-size: 24px;
    min-width: 70px;
  }
}