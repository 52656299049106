@font-face {
  font-family: "TAN-PEARL";
  src: url("../src/fonts/tan-pearl.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Anta";
  src: url("../src/fonts/Anta-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DM-Sans";
  src: url("../src/fonts/DM-Sans/DMSans_24pt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}