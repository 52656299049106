@import '../fonts.css';

/* Remove the .about-page-wrapper styles since we're using .homepage from Home.css */

.homepage-about {
    height: 100%;
  }
  
  .container-about {
    width: 100%; 
    display: flex;
    align-items: center; 
    margin-top: 0px;
    flex-direction: column;
    justify-content: center; 
  }
  
  /* Title */
  
  .title-about {
    margin-top: 0vh; 
  }
  
  .corner-image-about {
    position: absolute;
    top: 50px; /* Adjust to place the image where needed */
    left: 50px; /* Adjust to place the image where needed */
    width: 220px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    z-index: -1; /* Ensure it is layered correctly */
  }
  
  
  .title-image-about {
    display: block; 
    margin-right: auto; 
    margin-left: auto; 
    width: 70%; 
    height: auto; 
  }
  
  @media (max-width: 1600px) and (min-width: 1200px) {
    .title-image-about {
      width: calc(100% - 20vw); 
    }
    .corner-image-about {
      width: 200px;
    }
  }
  @media (max-width: 1200px) and (min-width: 768px) {
    .title-image-about {
      width: calc(100% - 10vw); 
    }
    .corner-image-about {
      width: 170px;
    }
  }
  @media (max-width: 768px) and (max-height: 480px) {
    .title-image-about {
      margin-top: 60vh;  
    }
    .corner-image-about {
      width: 150px;
    }
  }
  @media (max-width: 768px) {
    .title-image-about {
      width: 100%; 
    }
    .corner-image-about {
      width: 150px;
    }
  }
  @media (max-width: 600px) {
    .title-image-about {
      margin-top: 5rem;  
    }
}
  
.teams-section {
    min-height: 100vh;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(40px, 8vh, 80px);
    padding: clamp(20px, 5vw, 60px);

}

.team-section {
    width: 100%;
    max-width: min(90vw, 1200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(20px, 4vh, 40px);
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: clamp(15px, 3vw, 30px);
}

.member-image-container {
    width: clamp(100px, 25vw, 150px);
    height: clamp(100px, 25vw, 150px);
    border-radius: 15px;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.member-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; /* This centers the image */
}

/* Responsive typography */
.team-section h2 {
    font-size: clamp(24px, 5vw, 32px);
    margin-bottom: clamp(15px, 3vh, 30px);
}

.member-name {
    font-size: clamp(14px, 3vw, 16px);
}

/* Media queries for layout adjustments */
@media (min-width: 768px) {
    .teams-section {
        padding-top: clamp(100px, 15vh, 200px);
    }
}

@media (max-width: 767px) {
    .teams-section {
        padding-top: 60px;
    }
}

.team-name {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
}

.title-image {
  width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
}
