@import '../fonts.css';

.classespage .classes-title.MuiTypography-root {
    line-height: 80px;
}

.classes-subtitle.MuiTypography-root {
    margin-left: auto;
    margin-right: auto;
    font-size: 34px;
}

.link.register { font-size: 34px; }

@media (max-width: 480px) {
    .classes-subtitle.MuiTypography-root, .link.register {  font-size: 20px; }

}

.classes-title.MuiTypography-root {
    font-family: 'TAN-PEARL';
    font-size: 64px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0.68px;
    opacity: 1;
    text-align: center;
    color: white;
    text-shadow: 
        0 0 7px rgba(255, 255, 255, 0.7),
        0 0 10px rgba(255, 255, 255, 0.7),
        0 0 21px rgba(255, 255, 255, 0.7);
    margin-bottom: 40px; 
    margin-top: 10vh; 
}

@media (max-height: 768px) {
    .classes-title.MuiTypography-root {
        margin-top: 15vh;
        
    }
  }
  @media (max-height: 480px) {
    .classes-title.MuiTypography-root {
        margin-top: 25vh;
    }
  }

  @media (max-width: 480px) {
    .classes-title.MuiTypography-root {
        font-size: 48px;
    }
  }
  @media (max-width: 340px) {
    .classes-title.MuiTypography-root {
        font-size: 32px;
    }
  }